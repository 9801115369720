@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap");

@layer base {
  .wrap {
    @apply px-36 mx-auto;
  }
  .wrap-res {
    @apply px-8 mx-auto;
  }
  .big-btn {
    @apply w-48 h-12 lg:h-16 bg-primary text-white inline-flex justify-center text-xs lg:text-base items-center rounded-md transition-all duration-150 hover:opacity-80 hover:-translate-y-3;
  }
  .big-btn-dark {
    @apply w-48 h-12 lg:h-16 bg-dark text-white inline-flex justify-center text-xs lg:text-base items-center rounded-md transition-all duration-150 hover:opacity-80 hover:-translate-y-3;
  }
  .med-btn {
    @apply w-36 h-12 bg-primary text-white flex justify-center items-center rounded-md transition-all duration-150 hover:opacity-80 hover:-translate-y-3;
  }
  .med-btn-trans {
    @apply w-36 h-12 bg-transparent border border-solid flex justify-center items-center rounded-md ease-in-out duration-150 hover:bg-dark hover:border-0 transition-all  hover:opacity-80 hover:-translate-y-3;
  }
  .med-btn-dark {
    @apply w-36 h-12 bg-dark text-white flex justify-center items-center rounded-md transition-all duration-150 hover:opacity-80 hover:-translate-y-3;
  }
  .small-title {
    @apply text-secondary text-xl;
  }
  .medium-title {
    @apply text-3xl text-dark font-medium;
  }
  .grid-min {
    grid-template-rows: auto 1fr;
  }
}
html {
  scroll-behavior: smooth;
}
